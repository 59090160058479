import React from "react"

import { logEvent } from "../_utils/analytics"

const HeaderMenuControl = (props) => {
    const { buttonCallback, children } = props

    function menuControlClick() {
        buttonCallback()

        logEvent("Internal Action", "Header", "Mobile - Menu Control")
    }

    return ( <button className="header-expansion_button" onClick={menuControlClick} type="button">
        { children }
    </button>)
}

export default HeaderMenuControl