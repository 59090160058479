import { useState, useEffect, useRef, useLayoutEffect } from "react"
import { logEvent } from "../_utils/analytics"

import { motion } from "framer-motion"

// Framer-Motion Variants
const containerVariants = {
    initial: {},
    loaderRevealed: {}
}

const loadingVariants = (reference, height) => ({
    initial: {
        y: reference.current ? -height : -600,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: ["easeIn", "easeOut"]
        }
    },
    loaderRevealed: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: ["easeIn", "easeOut"]
        }
    },
    imageRevealed: {
        y: -600,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: ["easeIn", "easeOut"],
        }
    }
})

const imageVariants = {
    hidden: {
        y: -50,
        opacity: 0
    },
    revealed: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'tween',
            duration: 0.55,
            ease: ["easeIn", "easeOut"]
        }
    }
}

const ProjectMainImage = (props) => {
    const { project } = props
    const containerReference = useRef(null)
    const [containerHeight, setContainerHeight] = useState(0)
    const [loaderLoaded, setLoaderLoaded] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)

    useLayoutEffect(() => {
        function updateHeight(){ setContainerHeight(containerReference.current.offsetHeight) }
        window.addEventListener('resize', updateHeight)
        updateHeight()
        return () => window.removeEventListener('resize', updateHeight)
    }, [])

    useEffect(() => setLoaderLoaded(true), [])

    const onLoadHandler = () => {
        setTimeout(() => {
            setLoaderLoaded(false)
            setImageLoaded(true)
        }, 1000)
    }

    return (
        <div className="project-image">
            <motion.div className="intro-image-container" variants={containerVariants} ref={containerReference}>
                <motion.div className="project-image-loading" variants={loadingVariants(containerReference, containerHeight)} initial="initial" animate={loaderLoaded ? (imageLoaded ? "imageRevealed" : "loaderRevealed") : "initial"} exit="imageRevealed">&nbsp;</motion.div>
                <picture className={ `intro-image image logo-${project.url}`}>
                    <source media="(max-width:720px)" srcSet={project.logo.keyshot.mobile.lq } />
                    <source media="(max-width: 1200px)" srcSet={project.logo.keyshot.mobile.hq } />
                    <motion.img
                        src={project.logo.keyshot.desktop.hq}
                        alt={`Client Logo for ${project.client}`}
                        onClick={ logEvent("Project", "Image", `Keyshot`) }
                        onLoad={onLoadHandler}
                        variants={imageVariants}
                        initial="hidden"
                        animate={imageLoaded ? "revealed" : "hidden" }
                    />
                    <motion.caption variants={imageVariants} initial="hidden" animate={imageLoaded ? "revealed" : "hidden" }>{`Client Logo for ${project.client}`}</motion.caption>
                </picture>
            </motion.div>
        </div>
    )
}

export default ProjectMainImage