import axios from 'axios'
import Cookies from 'js-cookie'

export const site_api = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
})

const tokenCookie = Cookies.get('token')

export async function getAuthToken() {
    console.log(tokenCookie)
    if (!tokenCookie || tokenCookie === undefined) {
        await site_api.get(`${process.env.REACT_APP_API_URI}/auth/`)
            .catch((error) => {
                throw new Error('Problem fetching the authentication token\n', error)
            })
    }
}