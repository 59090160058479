import React, { useEffect, useState } from "react"

const GALLERY_THUMBNAIL = (props) => {
    const { selected, image, onClick } = props

    const n = 'embla-thumbs__slide'

    const [className, setClassName] = useState(n)

    useEffect(() => {
        if (selected === true) { setClassName(`${n} ${n}--selected`) }
        else setClassName(n)
    }, [selected])

    return ( <div className={className}>
        <button type="button" className="embla-thumbs__slide__image" onClick={onClick}>
            <img alt={image.description} src={image.url.lq} className={`gallery-thumbnail`}/>
        </button>
    </div>)
}

export default GALLERY_THUMBNAIL