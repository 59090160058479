import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import BasePage from '../organism/org-global-page'

const UnderConstruction = () => {
    const location = useLocation()
    const [pageStrings, setPageStrings] = useState({})

    const inheritStrings = useMemo(() => {
        return (pageStringsData) => {
            setPageStrings(pageStringsData)
            document.title = pageStringsData?.page_title.en
        }
    }, [])

    return (
        <BasePage
            pageID={`under_construction`}
            setterCallback={inheritStrings}
            location={location}>
            <div className="page-content">
                <h1 className='primary_header'>{pageStrings?.page_header?.en ?? "Not Found" }</h1>
                <p className='secondary_text'>{pageStrings?.secondary_text?.en ?? "Not Found"}</p>
            </div>
        </BasePage>
    )
}

export default UnderConstruction