import React, { useEffect, useState, useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import ReactLenis, { useLenis } from "@studio-freight/react-lenis"

import CASESTUDY_INFORMATION from "../organism/org-casestudy-information"
import {
  SECTION_PLAIN,
  SECTION_GRID,
  SECTION_GALLERY,
  SECTION_HIGHLIGHT,
  SECTION_TESTIMONIAL,
} from "../organism/org-casestudy-section"

import axios from "axios"
import BasePage from "../organism/org-global-page"

const LenisOption = (isMobile) => ({
  lerp: isMobile ? 0.1 : 0.05,
  smoothTouch: false
})

const Project = () => {
  const { slug } = useParams()
  const location = useLocation()

  const [project, setProject] = useState({})
  const [sections, setSections] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const inheritStrings = useMemo(() => {
    return (pageStringsData) => {
        document.title = `${pageStringsData?.page_title.en} - ${project.title}`
        return project.url
    }
  }, [project])

  const lenis = useLenis()

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URI}/projects/${slug}/`)
      .then((response) => {
        let project_data = response.data
        setProject(project_data)
        
        let sections_data = project_data.case_study.sections.sort((a,b) => a.order - b.order)
        setSections(sections_data)

        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
        console.error("Error fetching projects api.", error)
      })
  }, [slug])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <BasePage
      pageID={`project`}
      setterCallback={inheritStrings}
      additionalClassNames={`project-${project.url}`}
      location={location}
      lenis={lenis}
    >
      <ReactLenis root options={LenisOption(window.innerHeight > 768)}>
        <CASESTUDY_INFORMATION
          project={project}
          sortedSections={sections}
        />
        { sections.map(function (section, i) {
          switch (section.type) {
            case "PLAIN":
              return <SECTION_PLAIN key={i} section={section} />
            case "GRID":
              return <SECTION_GRID key={i} section={section} />
            case "GALLERY":
              return <SECTION_GALLERY key={i} section={section} />
            case "HIGHLIGHT":
              return <SECTION_HIGHLIGHT key={i} section={section} />
            case "TESTIMONIALS":
              return <SECTION_TESTIMONIAL key={i} section={section} />
            default:
              console.error("Unknown section type:", section.type)
              return <p>Error Generating Section of Type: {section.type}</p>
          }
        })}
      </ReactLenis>
    </BasePage>
  )
}

export default Project
