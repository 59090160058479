import React from 'react';

import Header from '../organism/org-global-header';

const Music = () => {
    return (
        <div className='App'>
          <Header />
          <section className='App-header'>
            <h1>About Page</h1>
          </section>
        </div>
    )
}

export default Music