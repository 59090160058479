import { logEvent } from "../_utils/analytics"
import { ArrowRight } from "lucide-react"

import { motion } from "framer-motion"

const ProjectInfoAnchors = ({section, variants}) => {
    const compName = 'intro-hyperlinks'

    return (
        <motion.a id={`${compName}-${section.slug}`}
           className={`${compName}-link`}
           href={`#${section.slug}`}
           onClick={ logEvent("Project", "Anchor", section.slug)}
           variants={variants}>
                {section.name}
                <ArrowRight size={16} />
        </motion.a>)
}

export default ProjectInfoAnchors