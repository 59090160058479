import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactLenis, { useLenis } from '@studio-freight/react-lenis'

import BasePage from '../organism/org-global-page'
import ContactIntroduction from '../molecules/mol-contact-introduction'
import ContactForm from '../molecules/mol-contact-form'

const setEaseInOutQuint = (x) => x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2

const LenisOptions = {
    //lerp: 0.05,
    ease: setEaseInOutQuint,
    touchMultiplier: 0.75,
    syncTouch: true,
    // syncTouchLerp: 0.05
}

const Contact = () => {
    const location = useLocation()
    const [pageStrings, setPageStrings] = useState({})

    const lenis = useLenis()

    const inheritStrings = useMemo(() => {
        return (pageStringsData) => {
            document.title = pageStringsData?.page_title.en
            setPageStrings(pageStringsData)
        }
    }, [])

    return (
        <BasePage
            pageID="contact"
            setterCallback={inheritStrings}
            location={location}
            lenis={lenis}
        >
            <ReactLenis root options={LenisOptions}>
                <div className="section-empty_col">&nbsp;</div>
                <div className='contact_content-container'>
                    <ContactIntroduction pageStrings={pageStrings} />
                    <ContactForm pageStrings={pageStrings} />
                </div>
                <div className="section-empty_col">&nbsp;</div>
                <div className="section-empty_col">&nbsp;</div>
                {/* <div className='contact_image-container'>Image Goes Here</div> */}
                <div className="section-empty_col">&nbsp;</div>
            </ReactLenis>
        </BasePage>
    )
}

export default Contact