import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import { ArrowUp } from 'lucide-react'
import SocialHyperlink from '../atom/atm-global-social_hyperlink'

const containerVariants = {
    inital: {}, // No initial state needed for the parent
    visible: {
        transition: {
            staggerChildren: 0.25, // Add delay between animations of each child
        },
    },
}


const Footer = (props) => {
    const { strings, lenis } = props

    let today = new Date()

    return (
        <motion.footer className="home-section section-container section-footer">
            <FooterPrimaryText strings={{
                header_name: strings?.header_name,
                tagline_title: strings?.tagline_title
            }} containerVariants={containerVariants} />
            <FooterSecondaryText lenis={lenis} strings={{
                scroll_up: strings?.scroll_up,
                copyright: {
                    en: `${strings.copyright.en} © ${today.getFullYear()}`
                }
            }} />
        </motion.footer>
    )
}

const FooterPrimaryText = (props) => {
    const { strings } = props

    // Framer-Motion Variants
    const textVariants = {
        initial: {
            opacity: 0,
            y: 50,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.333 }
        }
    }

    return (
        <motion.div className='footer-primary_text' variants={containerVariants} initial="initial" animate="visible">
            <Link to='/contact'>
                <motion.h2 className='footer-tagline_title' variants={textVariants}>{strings?.tagline_title?.en ?? "s"}</motion.h2>
                <motion.h1 className='footer-header_name' variants={textVariants}>{strings?.header_name?.en ?? "s"}</motion.h1>
            </Link>
        </motion.div>
    )
}

const FooterSecondaryText = (props) => {
    const { strings, lenis } = props

    // Framer-Motion Variants
    const textVariants = {
        initial: {
            opacity: 0,
            y: 50,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.75,
                duration: 0.75
            }
        }
    }

    function handleScrollUpClick () {
        if (lenis !== undefined || lenis === null) {
            console.warn("Lenis instance not implement")
            window.scrollTo(0, 0)
        }

        lenis.scrollTo(0)
    }

    return (
        <motion.div className='footer-secondary_text' variants={{ initial: {}, visible: {transition: {delay: 1.0}} }} viewport={{once: true}} initial="initial" animate="visible" >
            <motion.button className='footer-scroll_up-container' variants={textVariants} onClick={handleScrollUpClick}>
                <ArrowUp size={24} />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <p className='footer-scroll_up'>{strings?.scroll_up?.en ?? ""}</p>
            </motion.button>
            <motion.div className='footer-copyright' variants={textVariants}>
                <p className="footer-copyright">{(strings.copyright.en) ?? ""}</p>
            </motion.div>
            <motion.div className='footer-social_links-container' variants={textVariants}>
                <SocialHyperlink externalDestination= "https://www.linkedin.com/in/aramisperezdesign/" linkType= "LinkedIn" origin="footer" />
                <SocialHyperlink externalDestination= "https://www.instagram.com/artemis_perez/" linkType= "Instagram" origin="footer" />
                <SocialHyperlink externalDestination= "https://www.figma.com/@pereza024" linkType= "Figma" origin="footer" />
            </motion.div>
        </motion.div>
    )
}

export default Footer