import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { site_api, getAuthToken } from '../_utils/axios'

import { motion } from 'framer-motion'
import { InputField, TextfieldField } from "../atom/atm-global-fields"
import { SendHorizonal } from "lucide-react"

const inputVariants = {
    hidden: { opacity: 0, x: -10 }, // Start off-screen or hidden
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } }, // Animate to full opacity and position
}

const formVariants = {
    hidden: {}, // No initial state needed for the parent
    visible: {
        transition: {
            staggerChildren: 0.25, // Add delay between animations of each child
        },
    },
}

const ContactForm = (props) => {
    const { pageStrings } = props

    const [loading, setLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)

    const formID = "contact"
    
    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm({ criteriaMode: "all" })

    useEffect(() => {
        if(pageStrings) {
            setLoading(false)
        }
    }, [setLoading, pageStrings])

    const onSubmit = async (data) => {
        setDisabled(true)
        
        await getAuthToken()

        try {
            // Send POST request
            const response = await site_api.post(`/contact/`, data)
            console.log(response)
            // Reset Form 
            reset()

        } catch (error) {
            console.error(error)
            // Incase of failure, send an error to the form as a root cause

            // In the case of DB errors
            if (error.status === 400) {
                setError("root", {
                    message: error.response.data.message
                })
            }

            // In the case of Network connectivity issues
            if (error.status === 500) {
                setError("root", {
                    message: "Problem with the connection. Please try again later"
                })
            }
        } finally { setDisabled(false) }

    }

    if (loading) { return <></> }

    return (
        <section className="contact_form-container">
            <h2 className="contact_form-header">{ pageStrings.form_header?.en ?? "Undefined String" }</h2>
            <motion.form
                id={formID}
                className="contact_form-form"
                onSubmit={handleSubmit(onSubmit)}
                variants={formVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{once: true}}
            >
                <div className="contact_form-names" >
                    <InputField
                        fieldName="first_name"
                        fieldOrigin={formID}
                        formStrings={pageStrings.form}
                        register={register}
                        required
                        disabled={disabled}
                        errors={errors.first_name}
                        rules={{
                            required: pageStrings.form.errors.required_field_blank.en
                        }}
                        variants={inputVariants}
                    />
                    <InputField
                        fieldName="last_name"
                        fieldOrigin={formID}
                        formStrings={pageStrings.form}
                        register={register}
                        required
                        disabled={disabled}
                        errors={errors.last_name}
                        rules={{
                            required: pageStrings.form.errors.required_field_blank.en
                        }}
                        variants={inputVariants}
                    />
                </div>
                <InputField
                    fieldName="email"
                    fieldOrigin={formID}
                    formStrings={pageStrings.form}
                    register={register}
                    required
                    disabled={disabled}
                    errors={errors.email}
                    rules={{
                        required: pageStrings.form.errors.required_field_blank.en,
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid email format"
                        },
                    }}
                    variants={inputVariants}
                />
                <TextfieldField
                    fieldName="message"
                    fieldOrigin={formID}
                    formStrings={pageStrings.form}
                    register={register}
                    required={false}
                    disabled={disabled}
                    errors={errors.message}
                    rules={{
                        maxLength: {
                            value: 2400,
                            message: pageStrings.form.errors.over_char_limit.en
                        }
                    }}
                    variants={inputVariants}
                />
                <div className="form-action-container">
                    <FormSubmit 
                        buttonString={ pageStrings.form.submit.label }
                        disabled={disabled}
                        variants={inputVariants}
                    />
                    { errors && <p>{ errors.root?.message }</p> }
                </div>
            </motion.form>
        </section>
    )
}

const FormSubmit = (props) => {
    const { buttonString, disabled, variants } = props

    if (!buttonString) { return <></> }

    return (
        <motion.button type="submit" className={"field-submit".concat(disabled ? ' field-disabled' : '')} disabled={disabled} variants={variants}>
            {buttonString.en}
            <SendHorizonal size={16} />
        </motion.button>
    )
}

export default ContactForm