import ReactGA from "react-ga4"

const GA_TRACKING_ID = process.env.REACT_APP_GTAG

export const initGA = () => {
    if (GA_TRACKING_ID) {
        ReactGA.initialize([{
            trackingId: GA_TRACKING_ID
        }])
    }
}

export const logPageView = (pagePath, pageTitle) => {
    if (GA_TRACKING_ID) {
        ReactGA.send({
            hitType: "pageview",
            page: pagePath,
            title: pageTitle
        })
    }
}

export const logEvent = (category, action, label) => {
    if (GA_TRACKING_ID) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        })
    }
}