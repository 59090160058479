import React from "react"

import ProjectInfo from "../molecules/mol-project-info"
import ProjectMainImage from "../atom/atm-project-main_image"

const CASESTUDY_INFORMATION = ({project, sortedSections}) => {
    return(
        <section className={`project-section section-container project-intro`}>
            <div className="section-empty_col">&nbsp;</div>
            <ProjectInfo project={project} sortedSections={sortedSections} />
            <div className="section-empty_col">&nbsp;</div>
            <ProjectMainImage project={project} />
            <div className="section-empty_col">&nbsp;</div>
        </section>
    )
}

export default CASESTUDY_INFORMATION