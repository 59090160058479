import React, { useEffect, useState, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { ReactLenis, useLenis } from '@studio-freight/react-lenis'

import axios from "axios"

import BasePage from "../organism/org-global-page"
import HomeIntro from "../organism/org-home-intro"
import HomeProjectSection from "../organism/org-home-project"

const Home = () => {
  const location = useLocation()
  const [projects, setProjects] = useState([])
  const [strings, setStrings] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const setEaseInOutQuint = (x) => x < 0.5 ? 16 * x * x * x * x * x : 1 - Math.pow(-2 * x + 2, 5) / 2

  const LenisOptions = {
    //lerp: 0.05,
    ease: setEaseInOutQuint,
    touchMultiplier: 0.75,
    syncTouch: true,
    // syncTouchLerp: 0.05
  }

  const lenis = useLenis(({ scroll, velocity}) => {
    if (loading) return

    if (Math.abs(velocity) < 0.05) {
      const sections = document.querySelectorAll('.home-section')

      // TODO: Look into when a user uses the scrollbar rather then scrolling down with the wheel

      let closestSection = sections[0]
      let minDistance = Math.abs(closestSection.offsetTop - Math.round(scroll))
      let nextSection = null, previousSection = null
  
      sections.forEach((section) => {
        const sectionTop = Math.round(section.offsetTop)
        const sectionHeight = section.offsetHeight
        const sectionBottom = sectionTop + sectionHeight
        const distance = Math.abs(sectionTop - Math.round(scroll))
        
        if (distance < minDistance) {
          minDistance = distance
          closestSection = section
        }

        if (sectionTop > Math.round(scroll) && !nextSection) {
          nextSection = section
        }

        if (sectionBottom < Math.round(window.scrollY)) {
          previousSection = section
        }
      })

      // Calculate Snapping Trigger Threshold
      const closestSectionTop = closestSection.offsetTop
      const closestSectionHeight = closestSection.offsetHeight
      const scrollProgress = (Math.round(scroll) - closestSectionTop) / closestSectionHeight

      let targetSection = closestSection

      if (scrollProgress >= 0.125 && nextSection) {
        targetSection = nextSection // Move down
      } else if (scrollProgress <= -0.5 && previousSection) {
        targetSection = previousSection // Move up
      }

      const targetOffsetTop = Math.round(targetSection.offsetTop) - 81
      const isAtTarget = Math.abs(Math.round(scroll) - targetOffsetTop) < 2
      
      if (isAtTarget) return
      
      requestAnimationFrame(() => (
        lenis.scrollTo(targetSection, {
          offset: -81, easing: setEaseInOutQuint
        })
      ))
    }
  }, [])

  const pageVariants = {
    inital: {},
    revealed: {
      transition: {
        staggerChildren: 0.25
      }
    }
  }

  const inheritStrings = useMemo(() => {
      return (pageStringsData) => {
          document.title = pageStringsData?.page_title.en
          setStrings(pageStringsData)
          return null
      }
  }, [])

  function getProjects() {
    axios.get(`${process.env.REACT_APP_API_URI}/projects/`, {
        withCredentials: true,
      })
      .then((response) => {
        var array = response.data.sort((a, b) => a.order - b.order)
          .filter(function(item) {
            if (item.is_hidden === true) { return false }
            return true
          })

        setProjects(array)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
        console.error("Error fetching projects api.", error)
      })
  }

  useEffect(() => {
    getProjects()
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return (
    <BasePage
      pageID="home"
      setterCallback={inheritStrings}
      location={location}
      variants={pageVariants}
      lenis={lenis}
    >
      <ReactLenis 
        root
        options={LenisOptions}>
        <HomeIntro strings={strings?.intro} />
        { projects.map((project, i) => {
          return <HomeProjectSection key={i} project={project} sectionInformation={{
            index: i,
            sectionsLength: projects.length
          }} />
        }) }
      </ReactLenis>
    </BasePage>
  )
}

export default Home
