import { useEffect, React, useState } from 'react'
import { motion } from 'framer-motion'

import axios from 'axios'

import Header from './org-global-header'
import Footer from './org-global-footer'
import { logPageView } from '../_utils/analytics'

const BasePage = ((props) => {
    const {
        pageID,
        setterCallback,
        additionalClassNames,
        location,
        variants,
        ref,
        lenis,
        children } = props

    const [loading, setLoading] = useState(true)
    const [footerStrings, setFooterStrings] = useState(null)

    // Strings API 
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URI}/definitions/`)
            .then(respone => {
                let data = respone.data.pages[pageID]
                setFooterStrings(respone.data.pages.__footer)

                // Google Analytics Pageview Tagging

                // Utilizing the setterCallback to get back a URL in the cases that the page is
                // of a template class. IE: Projects, Music List
                
                logPageView(location.pathname, data.ga_tag_name + setterCallback(data) ?? "")

                setLoading(false)
            }).catch(error => {
                console.error('Error fetching definitions API', error)
            })
    }, [pageID, setterCallback, location])

    if(loading) {
        return <p>Loading...</p>
    }

    return (
        <div className={`page page-${pageID} ${additionalClassNames ?? ""}`}>
            <Header />
            <motion.div className='page-content' ref={ref} variants={variants} initial="inital">
                { children }
            </motion.div>
            <Footer strings={footerStrings} lenis={lenis}/>
        </div>
    )
})

export default BasePage