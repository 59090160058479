import React, { useRef, useState, useLayoutEffect } from "react"
import { motion, useScroll, useTransform } from "framer-motion"

const loadingVariants = (reference, height) => ({
    initial: {
        y: reference.current ? -height : -600,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: ["easeIn", "easeOut"]
        }
    },
    loaderRevealed: {
        y: 0,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: ["easeIn", "easeOut"]
        }
    },
    imageRevealed: {
        y: -600,
        transition: {
            type: 'tween',
            duration: 0.45,
            ease: ["easeIn", "easeOut"],
        }
    }
})

const HomeProjectSection = (props) => {
    const { project, sectionInformation } = props
    const ref = useRef(null)
    const [containerHeight, setContainerHeight] = useState(0)
    const { scrollYProgress } = useScroll({ target: ref, offset: ["start -47.35%", "end 157%"] })

    
    const y = useParallax(scrollYProgress, containerHeight / 2)
    const opacity = useTransform(scrollYProgress, [0, 0.125, 0.85, 1], [0, 1, 1, 0])
    
    useLayoutEffect(() => {
        function updateHeight(){ setContainerHeight(ref.current.offsetHeight) }
        window.addEventListener('resize', updateHeight)
        updateHeight()
        return () => window.removeEventListener('resize', updateHeight)
    }, [])
    
    function useParallax(value, distance) {
        return useTransform(value, [0, 0.5, 1], [-distance, 0, distance])
    }

    // Framer-Motion Variants
    const containerVariants = {
        initial: {},
        revealed: {}
    }

    return (
        <motion.section
        className={`home-section section-container home-project home-${project.url}`}
        variants={containerVariants}
        initial="initial"
        whileInView="revealed"
        viewport={{amount: 0.25}}
        ref={ref}
        >
            <div className="section-empty_col">&nbsp;</div>
            <HomeProjectImage url={project.logo.keyshot} alt={project.description} parallaxYPosition={y} parallaxOpacity={opacity}/>
            <div className="section-empty_col">&nbsp;</div>
            <HomeProjectInfo title={project.title} projectStart={project.start_date} projectEnd={project.end_date} parallaxYPosition={y} parallaxOpacity={opacity}/>
            <div className="section-empty_col">&nbsp;</div>
            <motion.div className="project-link-container" style={{ y, opacity }}>
                <a href={`/projects/${project.url}`} className={`project-link link-${project.url}`}>View Project</a>
            </motion.div>
            <div className="section-empty_col">&nbsp;</div>
            <div className="section-index">
                <span>{`${sectionInformation.index + 1} / ${sectionInformation.sectionsLength}`}</span>
            </div>
        </motion.section>
    )
}

const HomeProjectInfo = (props) => {
    const { title, projectStart, projectEnd, parallaxYPosition, parallaxOpacity } = props
    const containerReference = useRef(null)
    const [containerHeight, setContainerHeight] = useState(0)

    useLayoutEffect(() => {
        function updateHeight(){ setContainerHeight(containerReference.current.offsetHeight) }
        window.addEventListener('resize', updateHeight)
        updateHeight()
        return () => window.removeEventListener('resize', updateHeight)
    }, [])

    const startDate = new Date(projectStart)
    var endDate = null
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] // TODO: Localize
    
    if (projectEnd !== null) { endDate = new Date(projectEnd) } // TODO: Handle error case

    return (
        <motion.div className="project-info-container" style={{ y: parallaxYPosition, opacity: parallaxOpacity }} ref={containerReference}>
            <motion.div className="project-info-loading" variants={loadingVariants(containerReference, containerHeight)}>&nbsp;</motion.div>
            <h2 className="project-info-title">{title}</h2>
            <p className="project-info-timerange">{`${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}
                - ${endDate ? `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}` : `On Going` }`}</p>
        </motion.div>
    )
}

const HomeProjectImage = (props) => {
    const { url, altText, parallaxYPosition, parallaxOpacity } = props
    const containerReference = useRef(null)
    const [containerHeight, setContainerHeight] = useState(0)

    useLayoutEffect(() => {
        function updateHeight(){ setContainerHeight(containerReference.current.offsetHeight) }
        window.addEventListener('resize', updateHeight)
        updateHeight()
        return () => window.removeEventListener('resize', updateHeight)
    }, [])
    
    return (
        <motion.div className="project-image-container" style={{ y: parallaxYPosition, opacity: parallaxOpacity }} ref={containerReference}>
            <motion.div className="project-image-loading" variants={loadingVariants(containerReference, containerHeight)}>&nbsp;</motion.div>
            <figure className="project-image-wrapper image-wrapper">
                <img src={url.desktop.hq } alt={altText} className="project-image image" />
            </figure>
        </motion.div>
    )
}

export default HomeProjectSection