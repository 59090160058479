import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

import { initGA } from './component/_utils/analytics'
import UnderConstruction from './component/page/page-under_construction'

import Home from './component/page/page-home'
import Music from './component/page/page-music'
import About from './component/page/page-about'
import Contact from './component/page/page-contact'
import Project from './component/page/page-project'
import Portfolio from './component/page/page-portfolio'

function App() {
  useEffect(() => {
    initGA()
  }, [])

  if (process.env.REACT_APP_DISABLE_SITE === "TRUE") {
    return (
      <Router>
        <Routes>
          <Route exact path='/' Component={UnderConstruction} />
          <Route path='/contact' Component={Contact} />
          {/* Wildcard Designation is to catch all non-assigned URIs */}
          <Route path='*' element={<Navigate to="/" />} />
        </Routes>
      </Router>
    )
  }

  return (
    <Router>
      <Routes>
        <Route exact path='/' Component={Home} />
        <Route path='/music' Component={Music} />
        <Route path='/about' Component={About} />
        <Route path='/contact' Component={Contact} />
        <Route path='/projects' Component={Portfolio} />
        <Route path='/projects/:slug' Component={Project}/>
        
        <Route exact path='under-construciton' Component={UnderConstruction} />
      </Routes>
    </Router>
  )

}

export default App