import React, { useEffect, useState } from "react"
import { site_api } from '../_utils/axios'
import { motion } from "framer-motion"

const compName = "descriptor"

const ProjectInfoDescriptionList = ({listType, listItems, variants}) => {
    const [list] = useState({
        type: listType,
        items: listItems
    })
    const [definitions, setDefinitions] = useState({})

    function getClassName (listItem) {
        return `${compName}-list-item list-item ${listType.toLowerCase()}-${listItem.toLowerCase()}`
    }

    useEffect(() => {
        site_api.get(`/definitions/`)
            .then(response => {
                let data = response.data

                for (var t in data.project.list) {
                    if (t === listType) {
                        setDefinitions(data.project.list[t])
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching definitions api.', error)
            })
    }, [listType])
    
    return (
        <motion.div className={`${compName}-container descriptor-${list.type.toLowerCase()}`} variants={variants} viewport={{ amount: 0.8 }}>
            <h3 className={`${compName}-title section-header`}>{list.type}</h3>
            <ul className={`${compName}-list`}>{
                list.items.map(function (item, i) {
                    for (var def in definitions) {
                        if (def === item) { return <li key={i} className={getClassName(item)}>{definitions[item].long_string}</li> }
                    }
                    return false
                })
            } </ul>
        </motion.div>
    )
}

const ProjectInfoDurationList = ({listType, project_start, project_end, variants}) => {
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] // TODO: Localize
    
    const startDate = new Date(project_start)
    
    let endDate = null
    if (project_end !== null) { endDate = new Date(project_end) }

    return (
        <motion.div className={`${compName}-container descriptor-${listType.toLowerCase()}`} variants={variants} viewport={{ amount: 0.8 }}>
            <h3 className={`${compName}-title`}>DURATION</h3>
            <div className={`${compName}-duration`}>
                <p className={`${compName}-list-item list-item`}>{`${monthNames[startDate.getMonth()]} ${startDate.getFullYear()} - ${project_end !== null ? `${monthNames[endDate.getMonth()]} ${endDate.getFullYear()}` : 'On Going'}`}</p>
            </div>
        </motion.div>
    )
}

export { ProjectInfoDescriptionList
, ProjectInfoDurationList }