import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

import axios from "axios"
import { logEvent } from "../_utils/analytics"

import BasePage from "../organism/org-global-page"

import PROJECT_THUMBS from "../atom/atm-portfolio-project_thumbs"

const Portfolio = () => {
    const location = useLocation()
    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URI}/projects/`, {
                withCredentials: true
            })
            .then((response) => {
                var array = response.data.sort((a, b) => a.order - b.order)
                    .filter(function(item) {
                        if (item.is_hidden === true) { return false }
                        return true
                    })
                setProjects(array)
                setLoading(false)
            })
            .catch((error) => {
                setError(error)
                setLoading(false)
                console.error("Error fetching projects api.", error)
            })
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>
    }

    return (
        <BasePage
            pageID="portfolio"
            setterCallback={(pageTitle) => { document.title = pageTitle }}
            location={location}
        >
            <div className="projects-section_container">
                <div className="projects-section">
                    <div className="projects-title_container">
                        <h2 className="projects-title">Projects</h2>
                    </div>
                    <div className="projects-list">
                        { projects.map((project, i) => (
                        <PROJECT_THUMBS
                            key={i}
                            project={project}
                            GA_EventCallBack={() => {
                                logEvent("Internal Link", "Home - Project List", project?.url)
                            }}
                        />
                        ))}
                    </div>
                </div>
            </div>
        </BasePage>
    )

}

export default Portfolio