import ProjectInfoAnchors from "../atom/atm-casestudy-hyperlink"
import { ProjectInfoDescriptionList, ProjectInfoDurationList } from "../molecules/mol-casestudy-descriptor_list"

import { motion } from "framer-motion"

const childrenVariants = {
    initial: { opacity: 0, x: -10 }, // Start off-screen or hidden
    revealed: { opacity: 1, x: 0, transition: { duration: 0.3 } }, 
}

const containerVariants = {
    initial: {},
    revealed: {
        transition: {
            staggerChildren: 0.25, // Add delay between animations of each child
        }
    }
}

const ProjectInfo = (props) => {
    const { project, sortedSections } = props

    return (
        <motion.div className="project-info" variants={containerVariants} initial="initial" whileInView="revealed" viewport={{ once: true }}>
            <div className="intro-title-container">
                <motion.h1 className="intro-title-title" variants={childrenVariants} viewport={{ amount: 0.8 }}>{project.title}</motion.h1>
                <motion.h2 className="intro-title-client" variants={childrenVariants} viewport={{ amount: 0.8 }}>{project.client}</motion.h2>
                {/* <motion.div className="intro-title-type project-type-container" variants={childrenVariants}>Tags Go Here</motion.div> */}
            </div>
            <motion.div className="intro-project_goal-container" variants={childrenVariants} viewport={{ amount: 0.8 }}>
                <h2 className="intro-project_goal-header section-header">Project Goal</h2> {/* TODO: Replace this with the definitions API */}
                <p className="intro-project_goal-description">{project.description}</p>
            </motion.div>
            <div className="intro-descriptor-container">
                <ProjectInfoDescriptionList
                    listType={"ROLE"}
                    listItems={project.case_study.roles}
                    variants={childrenVariants}
                />
                <ProjectInfoDescriptionList
                    listType={"DELIVERABLE"}
                    listItems={project.case_study.deliverables}
                    variants={childrenVariants}
                />
                <ProjectInfoDescriptionList
                    listType={"TEAM"}
                    listItems={project.case_study.teammates}
                    variants={childrenVariants}
                />
                <ProjectInfoDurationList
                    listType={"DUR"}
                    project_start={project.start_date}
                    project_end={project.end_date}
                    variants={childrenVariants}
                />
            </div>
            <motion.hr className="intro-divider" variants={childrenVariants} />
            <div className="intro-hyperlinks-container"> {
                sortedSections.map(function (section, i) {
                    return <ProjectInfoAnchors key={i} section={section} variants={childrenVariants}/>
                })
            } </div>
        </motion.div>
    )
}

export default ProjectInfo