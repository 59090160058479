import React from "react"

import SocialHyperlink from "../atom/atm-global-social_hyperlink"

const ContactIntroduction = (props) => {
    const { pageStrings } = props

    return (
        <section className="contact_introduction-container">
            <h1 className="contact_introduction-header">{ pageStrings.primary_text?.en ?? "Undefined String" }</h1>
            <h2 className="contact_introduction-intro_subtext">{ pageStrings.intro_subtext?.en ?? "Undefined String" }</h2>
            <div className="contact_introduction-social_links-container">
                    <SocialHyperlink externalDestination= "https://www.linkedin.com/in/aramisperezdesign/" linkType= "LinkedIn" origin="contact_introduction" size={32} />
                    <SocialHyperlink externalDestination= "https://www.instagram.com/artemis_perez/" linkType= "Instagram" origin="contact_introduction" size={32} />
                    <SocialHyperlink externalDestination= "https://www.figma.com/@pereza024" linkType= "Figma" origin="contact_introduction" size={32} />
            </div>
        </section>
    )
}

export default ContactIntroduction