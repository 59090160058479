import React, { useCallback, useEffect, useState } from "react"
import { motion } from "framer-motion"

import { ArrowLeft, ArrowRight } from 'lucide-react'
import useEmblaCarousel from 'embla-carousel-react'

import GALLERY_THUMBNAIL from "../atom/atm-embla-thumbnail"

const baseName = "case_study-section"

const containerVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.25
        }
    }
}

const childrenVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
}

const CASESTUDY_SECTION = ({section, children}) => {
    const className = `${baseName} ${baseName}-container ${baseName}_${section.type.toLowerCase()}`

    return (
        <motion.section id={section.slug} className={`${className} section-container`} variants={containerVariants} initial="hidden" whileInView="visible" viewport={{ once: true, amount: 0.125 }}>
            <div className="section-empty_col">&nbsp;</div>
            { children }
            <div className="section-empty_col">&nbsp;</div>
        </motion.section>
    )
}

const SECTION_PLAIN = ({section}) => {
    return (
        <CASESTUDY_SECTION section={section} >
            <div className={`${baseName}-section_container`}>
                <motion.h2 className={`${baseName}-name`} variants={childrenVariants}>{ section.name }</motion.h2>
                <div className={`${baseName}-content_container`}>
                    <motion.div className={`${baseName}-text_content`} variants={childrenVariants} dangerouslySetInnerHTML={{ __html: section.markdown }} />
                </div>
            </div>
        </CASESTUDY_SECTION>
    )
}

const SECTION_HIGHLIGHT = ({section}) => {
    return (
        <CASESTUDY_SECTION section={section}>
            <div className={`${baseName}-section_container`}>
                <motion.h2 className={`${baseName}-name`} variants={childrenVariants}>{ section.name }</motion.h2>
                <div className={`${baseName}-content_container`}>
                    <motion.div className={`${baseName}-text_content`} variants={childrenVariants} dangerouslySetInnerHTML={{ __html: section.markdown }} />
                    <div className={`${baseName}-grid grid grid_container`}>
                        { section.images.filter(function(image) {
                            if (image?.is_hidden !== false) { return false }
                            return true
                        }).map(function(image, i) {
                            return <motion.img key={i}
                                        alt={image.description}
                                        src={image.url.hq}
                                        className={`${baseName}-grid_item grid_item`}
                                        variants={childrenVariants}
                                    />
                        })}
                    </div>
                </div>
            </div>
        </CASESTUDY_SECTION>
    )
}

const SECTION_TESTIMONIAL = ({section}) => {
    return ( <CASESTUDY_SECTION section={section}>
        <div className={`${baseName}-section_container`}>
            <motion.h2 className={`${baseName}-name`} variants={childrenVariants}>{ section.name }</motion.h2>
            <div className={`${baseName}-content_container`}>
                <motion.div className={`${baseName}-text_content`} variants={childrenVariants} dangerouslySetInnerHTML={{ __html: section.markdown }} />
            </div>
        </div>
    </CASESTUDY_SECTION>)
}

const SECTION_GRID = ({section}) => {
    return (
        <CASESTUDY_SECTION section={section}>
            <div className={`grid grid_container`}>
                { section.images.map(function (image, i) {
                    return (<img key={i} alt={image.description} src={image.url.hq} className={`${baseName}-grid_item grid_item`}/>)
                }) } 
            </div>
        </CASESTUDY_SECTION>
    )
}

const SECTION_GALLERY = ({section}) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel({ loop: true })
    
    const onThumbnailClick = useCallback((i) => {
        if (emblaMainApi) { return emblaMainApi.scrollTo(i) } 
    }, [emblaMainApi])

    const onNextClick = useCallback(() => {
        if (emblaMainApi) { return emblaMainApi.scrollNext(true) }
    }, [emblaMainApi])

    const onPreviousClick = useCallback(() => {
        if (emblaMainApi) { return emblaMainApi.scrollPrev(true) }
    }, [emblaMainApi])

    const onSelect = useCallback(() => {
        if (!emblaMainApi) { return }

        setSelectedIndex(emblaMainApi?.selectedScrollSnap())

    }, [emblaMainApi, setSelectedIndex])

    useEffect(() => {
        if (!emblaMainApi) { return }
        
        onSelect()

        emblaMainApi?.on('select', onSelect).on('reInit', onSelect)
    }, [emblaMainApi, onSelect])


    return (
        <CASESTUDY_SECTION section={section}>
            <div className={`${baseName}-gallery gallery gallery_container`}>
                <div className="embla">
                    <div className="embla__viewport" ref={emblaMainRef}>
                        <div className="embla__container">
                            { section.images.filter(function(image) {
                                if (image?.is_hidden !== false) { return false }
                                return true
                            }).map(function(image, i) {
                                return ( <div className="embla__slide" key={i}>
                                    <div className="embla__slide__image">
                                        <img alt={image.description} src={image.url.hq} className={`${baseName}-grid_item grid_item`}/>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                    <div className="gallery-controls_container">
                        <button className="gallery-control gallery-control_previous" type="button" title="Carousel - Previous" onClick={() => onNextClick()}><ArrowLeft size={12} /></button>
                        <button className="gallery-control gallery-control_next" type="button" title="Carousel - Next" onClick={() => onPreviousClick()}><ArrowRight size={12} /></button>
                    </div>
                </div>
                <div className="gallery-thumbs_container">
                    <div className="embla-thumbs__viewport">
                        { section.images.filter(function(image) {
                            if (image?.is_hidden !== false) { return false }
                            return true
                        }).map(function(image, i) {
                            return <GALLERY_THUMBNAIL
                                key={i}
                                onClick={() => onThumbnailClick(i)}
                                selected={i === selectedIndex}
                                image={image}
                            />
                        })}
                    </div>
                </div>
            </div>
        </CASESTUDY_SECTION>
    )
}

export { SECTION_PLAIN, SECTION_HIGHLIGHT, SECTION_GRID, SECTION_GALLERY, SECTION_TESTIMONIAL }