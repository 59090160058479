import React from "react"
import { Link } from "react-router-dom"

const PROJECT_THUMBS = (props) => {
    const { project, GA_EventCallBack } = props

    return (
        <Link to={`/projects/${project.url}`} onClick={GA_EventCallBack}>
            <div className="item-container">
            <div className="item-image_container">
                <img alt="Alt Text" className="item-image" src={project.logo?.keyshot.desktop.hq} />
            </div>
            <div className="item-text_container">
                <h3 className="item-title">{project.title}</h3>
                <p className="item-description">{project.description}</p>
            </div>
            </div>
        </Link>
    )
}

export default PROJECT_THUMBS