import { logEvent } from "../_utils/analytics"

import { Figma, Instagram, Linkedin } from 'lucide-react'

const SocialHyperlink = (props) => {
    const {externalDestination, linkType, origin, size } = props

    function setGALabel() {
        if (origin === "footer") { return `Footer - ${linkType}` }
        if (origin === "contact_introduction") { return `Contact - ${linkType}` }
        throw new Error("Attempting to set an invalid origin to the GA Event")
    }


    return <a href={externalDestination} target="_blank" rel='noreferrer' className={`${origin}-hyperlink`} onClick={() => {
        try {
            logEvent( "External Links", "Social Media Link",  setGALabel() )
        } catch(e) { console.warn(e) }
    }} >
        { linkType === "Figma" ? <Figma size={size ?? 24} /> : <></> }
        { linkType === "Instagram" ? <Instagram size={size ?? 24} /> : <></> }
        { linkType === "LinkedIn" ? <Linkedin size={size ?? 24} /> : <></> }
    </a>
}

export default SocialHyperlink