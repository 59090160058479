import React from 'react'
import { motion } from 'framer-motion'

const FieldLabel = (props) => {
    const {
        isRequired,
        fieldName,
        fieldID,
        labelString,
        requiredString 
    } = props

    return (
        <div className='field-label-container'>
            <label htmlFor={fieldID} className={`field-label field-label-${fieldName}`}>{`${labelString?.en ?? "Undefined Label"}`}</label>
            { isRequired ? <strong className='field-label-required_tag'>{requiredString?.en ?? "Unclear"}</strong> : <></>}
        </div>
    )
}

const BaseField = (props) => {
    const {
        required,
        disabled,
        fieldName,
        fieldID,
        fieldStrings,
        generalStrings,
        errors,
        variants,
        children
    } = props

    if (!fieldStrings || !generalStrings) {
        console.warn(`Was unable to retrive strings for: ${fieldName}`)
        return <></>
    }

    return (
        <motion.div className={`field field-container field-${fieldName}`.concat(errors ? ' field-error' : '', disabled ? ' field-disabled' : '')} variants={variants}>
            <FieldLabel
                isRequired={required ?? false}
                fieldName={fieldName}
                fieldID={fieldID}
                labelString={fieldStrings.label}
                requiredString={generalStrings.required}
            />
            { children }
            <p className={`field-secondary_label ${errors ? 'field-error-message' : ''}`}>{ errors?.message}</p>
        </motion.div>
    )
}

const InputField = (props) => {
    const {
        fieldName,
        fieldOrigin,
        formStrings,
        register,
        required,
        disabled,
        rules,
        variants,
        errors
    } = props

    if (!formStrings) {
        console.warn(`Was unable to retrive strings for: ${fieldName}`)
        return <></>
    }

    function getID() { return (`${fieldOrigin}-${fieldName}`) }

    return (
        <BaseField
            fieldName={fieldName}
            fieldID={getID()}
            fieldStrings={formStrings[fieldName]}
            required={required}
            generalStrings={formStrings?.general_labels}
            errors={errors}
            disabled={disabled}
            variants={variants}
        >
            <input
                id={getID()}
                className={`field-input field-input-${fieldName}`}
                disabled={disabled}
                name={fieldName}
                placeholder={ formStrings[fieldName]?.placeholder?.en ?? "Undefined Placeholder" }
                type="text"
                { ...register(fieldName, rules) }
            />
        </BaseField>
    )
}

const TextfieldField = (props) => {
    const {
        fieldName,
        fieldOrigin,
        formStrings,
        register,
        required,
        disabled,
        rules,
        variants,
        errors
    } = props

    if (!formStrings) {
        console.warn(`Was unable to retrive strings for: ${fieldName}`)
        return <></>
    }

    function getID() { return (`${fieldOrigin}-${fieldName}`) }

    return (
        <BaseField
            fieldName={fieldName}
            fieldID={getID()}
            fieldStrings={formStrings[fieldName]}
            required={required}
            generalStrings={formStrings?.general_labels}
            errors={errors}
            disabled={disabled}
            variants={variants}
        >
            <div className='field-textfield-container'>
                <textarea
                    type="text"
                    id={getID()}
                    name={fieldName}
                    disabled={disabled}
                    placeholder={ formStrings[fieldName]?.placeholder?.en ?? "Undefined Placeholder" }
                    className={`field-textfield field-textfield-${fieldName}`}
                    { ...register(fieldName, rules) }
                />
                {
                    rules.maxLength.value || rules.maxLength.value > 0 ? <p className='field-textfield-max_char_count'>{`${rules.maxLength.value} ${formStrings.general_labels.character_limit.en}`}</p>
                    : <></>
                }
            </div>
        </BaseField>
    )
}

export { InputField, TextfieldField }