import React from 'react'
import { motion } from 'framer-motion'
import { ArrowDown } from 'lucide-react'

const HomeIntro = (props) => {
    const { strings } = props

    const containerVariants = {
        inital: {}, // No initial state needed for the parent
        visible: {
            transition: {
                staggerChildren: 0.25, // Add delay between animations of each child
            },
        },
    }

    return (
        <motion.section className="home-section section-container home-intro">
            <IntroPrimaryText strings={{
                header_name: strings?.header_name,
                tagline_title: strings?.tagline_title
            }} containerVariants={containerVariants} />
            <IntroSecondaryText strings={{
                scroll_down: strings?.scroll_down,
                welcome: strings?.welcome
            }} />
        </motion.section>
    )
}

const IntroPrimaryText = (props) => {
    const { strings, containerVariants } = props
    const nameArray = strings.header_name.en.split(' ')

    // Framer-Motion Variants
    const textVariants = {
        initial: {
            opacity: 0,
            y: 50,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.333 }
        }
    }

    return (
        <motion.div className='intro-primary_text' variants={containerVariants} initial="initial" animate="visible">
            <motion.h2 className='intro-tagline_title' variants={textVariants}>{strings.tagline_title.en}</motion.h2>
            <motion.h1 className='intro-header_name' variants={textVariants}>{
                nameArray.map((word, i) => {
                    if (i >= nameArray.length -1) {
                        return <span key={i}>{word}</span>
                    }
                    return <span key={i}>{word} <br /></span>
                })
            }</motion.h1>
        </motion.div>
    )
}

const IntroSecondaryText = (props) => {
    const { strings } = props

    // Framer-Motion Variants
    const textVariants = {
        initial: {
            opacity: 0,
            y: 50,
        },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.75,
                duration: 0.75
            }
        }
    }

    return (
        <motion.div className='intro-secondary_text' variants={{ initial: {}, visible: {transition: {delay: 1.0}} }} viewport={{once: true}} initial="initial" animate="visible" >
            <motion.div className='intro-scroll_down' variants={textVariants}>
                <ArrowDown size={24} />
                <p className='intro-text'>{strings.scroll_down.en}</p>
            </motion.div>
            <motion.div className='intro-welcome' variants={textVariants}>
                <p className='intro-text'>{strings.welcome.en}</p>
            </motion.div>
        </motion.div>
    )
}

export default HomeIntro