import React, { useState } from "react"
import { Link } from "react-router-dom"

import { Menu, X } from 'lucide-react' 
import { motion, AnimatePresence } from "framer-motion"

import HeaderMenuControl from "../atom/atm-header-menu_control"

let linkArray = [
    {
        "url" : '/',
        "name" : 'home'
    },
    {
        "url" : '/contact',
        "name" : 'contact'
    }
    
]

const Header = () => {
    const [isVisible, setIsVisible] = useState(false)
    
    return (
        <header className="section-site_header">
            <div className="header-container">
                <Link to={'/'} className="site-name">aramis perez</Link>
                <div className="site-pages">
                    { linkArray.map(function (link, i) {
                        return <Link key={i} to={link.url} className="site_header-link link">{link.name}</Link>
                    }) }
                </div>
                <div className="header-expansion_button_container">
                    <HeaderMenuControl buttonCallback={() => {setIsVisible(!isVisible)}}>
                        { isVisible ? <X size={18} /> : <Menu size={18} />}
                    </HeaderMenuControl>
                </div>
            </div>
            <AnimatePresence> {isVisible && <MobileHeaderExpansion />} </AnimatePresence>
        </header>
    )
}

const MobileHeaderExpansion = () => {
    return (
        <motion.div
            className="header-mobile_expasion"
            initial={{y: -1000 }}
            animate={{y: 0 }}
            exit={{y: -1000 }}
            transition={{
                type: "spring",
                stiffness: 764,
                damping: 100,
                mass: 5
            }}
            >
            <div className="site-pages ">
                { linkArray.map(function (link, i) {
                    return <Link key={i} to={link.url} className="site_header-link link">{link.name}</Link>
                }) }
            </div>
            <div className="site-social_hyperlinks">
                social media
            </div>
        </motion.div>
    )
}

export default Header